/**
 * @copyright @ 2021 Cognition Solutions. All rights reserved.
 * @description Reducer for SignUp page.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created  : 17/Aug/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------
import {
  SIGNUP_EMAIL_OTP_REQUEST,
  SIGNUP_EMAIL_OTP_VERIFY,
  SIGNUP_FAILURE,
  SIGNUP_RESEND_EMAIL_OTP_REQUEST,
  SIGNUP_RESET,
  SIGNUP_SUCCESS,
  SIGNUP_UPDATE_MOBILE_REQUEST,
} from "../../actions";

// ----------------------------------------------------------------------

/** Initial State */
const signUpInitialState = {
  loading: false,
  loadingResend: false,
  success: null,
  failure: null,
};

/**
 * Reducer method for particular action
 * @param {*} state initial state
 * @param {*} action particular action
 * @returns updated state
 */
export const signUp = (state = signUpInitialState, action = {}) => {
  switch (action.type) {
    case SIGNUP_EMAIL_OTP_REQUEST:
    case SIGNUP_EMAIL_OTP_VERIFY:
    case SIGNUP_UPDATE_MOBILE_REQUEST:
      return { ...state, loading: true };
    case SIGNUP_RESEND_EMAIL_OTP_REQUEST:
      return { ...state, loadingResend: true };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingResend: false,
        success: action.payload,
        failure: null,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        loadingResend: false,
        success: null,
        failure: action.payload,
      };
    case SIGNUP_RESET:
      return {
        ...state,
        loading: false,
        loadingResend: false,
        success: null,
        failure: null,
      };
    default:
      return state;
  }
};
