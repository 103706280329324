import { pxToRem } from "./formatFontSize";

// ----------------------------------------------------------------------

/**
 * To set fontsize according to the width of the screen.
 *
 * @param sm - small font size
 * @param md - medium font size
 * @param lg - large font size
 * @returns font size based on the width of the screen
 */
function responsiveFontSizes({ sm, md, lg }) {
  return {
    "@media (min-width:768px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:1024px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1440px)": {
      fontSize: pxToRem(lg),
    },
  };
}

/* Constants */

const POPPINS_REGULAR = "Poppins-Regular";
const INTER = "Inter";

const typography = {
  fontFamily: POPPINS_REGULAR,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fonts: {
    Poppins_Regular: POPPINS_REGULAR,
    Inter: INTER,
  },
  h1: {
    fontFamily: POPPINS_REGULAR,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(45),
    ...responsiveFontSizes({ sm: 45, md: 45, lg: 60 }),
  },
  h2: {
    fontFamily: POPPINS_REGULAR,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 42, md: 44, lg: 46 }),
  },
  h3: {
    fontFamily: POPPINS_REGULAR,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(34),
    ...responsiveFontSizes({ sm: 36, md: 38, lg: 40 }),
  },
  h4: {
    fontFamily: POPPINS_REGULAR,
    lineHeight: 1.5,
    fontWeight: 600,
    fontSize: pxToRem(30),
    ...responsiveFontSizes({ sm: 32, md: 34, lg: 36 }),
  },
  h5: {
    fontFamily: POPPINS_REGULAR,
    lineHeight: 1.5,
    fontWeight: 600,
    fontSize: pxToRem(8),
    ...responsiveFontSizes({ sm: 28, md: 30, lg: 32 }),
  },
  h6: {
    fontFamily: POPPINS_REGULAR,
    lineHeight: 1.5,
    fontWeight: 600,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 20, md: 20, lg: 24 }),
  },

  subtitle1: {
    fontFamily: POPPINS_REGULAR,
    lineHeight: 1.5,
    fontWeight: 600,
    fontSize: pxToRem(18),
    // ...responsiveFontSizes({ sm: 16, md: 18, lg: 20 }),
  },
  subtitle2: {
    fontFamily: POPPINS_REGULAR,
    lineHeight: 1.5,
    fontWeight: 600,
    fontSize: pxToRem(16),
    // ...responsiveFontSizes({ sm: 16, md: 18, lg: 16 }),
  },
  subtitle3: {
    fontFamily: POPPINS_REGULAR,
    lineHeight: 1.5,
    fontWeight: 500,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 22, lg: 22 }),
  },

  body1: {
    fontFamily: POPPINS_REGULAR,
    lineHeight: 1.5,
    fontWeight: 500,
    fontSize: pxToRem(18),
    // ...responsiveFontSizes({ sm: 14, md: 16, lg: 18 }),
  },
  body2: {
    fontFamily: POPPINS_REGULAR,
    lineHeight: 1.5,
    fontWeight: 500,
    fontSize: pxToRem(16),
    // ...responsiveFontSizes({ sm: 14, md: 14, lg: 16 }),
  },
  body3: {
    fontFamily: POPPINS_REGULAR,
    lineHeight: 1.5,
    fontWeight: 500,
    fontSize: pxToRem(14),
    // ...responsiveFontSizes({ sm: 14, md: 14, lg: 16 }),
  },
  caption: {
    fontFamily: POPPINS_REGULAR,
    lineHeight: 1.5,
    fontWeight: 400,
    fontSize: pxToRem(12),
    // ...responsiveFontSizes({ sm: 10, md: 12, lg: 14 }),
  },
  overline: {
    // fontFamily: CIRCULARTT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.2,
    textTransform: "uppercase",
  },
  button: {
    fontFamily: POPPINS_REGULAR,
    fontWeight: 600,
    lineHeight: 24 / 16,
    fontSize: pxToRem(18),
  },
};

export default typography;
