export default {
  logoBox: (theme) => ({
    padding: theme.spacing(1, 13.125, 0, 13.125),
    backgroundColor: theme.palette.background.default,
    // [theme.breakpoints.down(1170)]: {
    //   padding: theme.spacing(1, 8, 0, 8),
    // },
  }),
  navbarContainer: (theme) => ({
    "& .MuiContainer-root": {
      padding: theme.spacing(0),
    },
  }),
  appBarContainer: (theme) => ({
    backgroundColor: "#FFFFFF",
  }),
  phoneNumberStack: (theme) => ({
    backgroundColor: theme.palette.grey[900],
  }),
  listItemButton: (theme) => ({
    padding: theme.spacing(0, 6),
    // [theme.breakpoints.down(1170)]: {
    //   width: "100%",
    // },
  }),
  phoneNumber: (theme) => ({
    color: theme.palette.grey[300],
  }),
  navItem: (theme) => ({
    color: theme.palette.grey[600],
  }),
  donateButton: (theme) => ({
    [theme.breakpoints.down(1170)]: {
      width: "100%",
    },
    color: theme.palette.background.default,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1.5, 3, 1.5, 3),
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(3),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  menuItem: (theme) => ({
    "& .active": {
      backgroundColor: theme.palette.background.main,
      color: theme.palette.info.main,
    },
  }),
  menuStyle: (theme) => ({
    padding: 0,
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "100%",
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.background.default,
    "& .MuiListItemButton-root": {
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,

        "& .MuiListItemText-root": {
          "&:hover": {
            color: theme.palette.primary.main,
            // backgroundColor: theme.palette.background.default,
          },
        },
      },
      "&.Mui-selected": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,

        "& .MuiListItemText-root": {
          color: theme.palette.primary.main,

          "& .MuiTypography-root": {
            fontWeight: theme.typography.fontWeightSemiBold,
          },
        },
      },
    },
  }),
  profilePicture: {
    height: 36,
    width: 36,
  },
  accountPopover: (theme) => ({
    width: 230,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 0, 2, 2),
  }),
  logout: (theme) => ({
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    borderRadius: 1,
    "&:hover": {
      backgroundColor: theme.palette.error.darker,
    },
  }),
};
