export default {
  fullName: (theme) => ({
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
  formContainer: (theme) => ({
    paddingX: theme.spacing(16),
    [theme.breakpoints.down("sm")]: {
      paddingX: theme.spacing(1),
    },
  }),
  formStack: (theme) => ({
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  }),
  grid: (theme) => ({
    "&.MuiGrid-root": {
      paddingTop: theme.spacing(2),
    },
  }),
  formBox: (theme) => ({
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
  selectBox: (theme) => ({
    width: "100%",
    // width: "50%",
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
    "&.MuiList-root": {
      padding: 0,
    },
  }),
  menuItemStyle: (theme) => ({
    "&.MuiList-root": {
      padding: 0,
    },
    backgroundColor: "#FFFFFF",
  }),
  checkBoxStack: (theme) => ({
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      direction: "column",
      marginTop: theme.spacing(3),
    },
  }),
  fullNameText: (theme) => ({
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "flex-start",
    },
  }),

  subTitle: (theme) => ({
    display: "flex",
    textAlign: "left",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "flex-start",
    },
  }),
  checkBoxText: (theme) => ({
    textAlign: "right",
    // marginRight: theme.spacing(7.5),
  }),
  volunteerForm: (theme) => ({
    textAlign: "left",
    margin: theme.spacing(6, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 5, 0),
    },
  }),

  uploadFilesButton: (theme) => ({
    marginRight: theme.spacing(7.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    padding: theme.spacing(2, 5.5, 2, 5.5),
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  }),

  uploadFilesButtonBox: (theme) => ({
    // width: "60%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  }),
  confirmText: (theme) => ({
    // maxWidth: "1090px",
    // margin: "auto",
    textAlign: "left",
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(5, 2, 2, 2),
    },
  }),
  submitButtonBox: (theme) => ({
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  }),
  submitButton: (theme) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    padding: theme.spacing(2, 5.5, 2, 5.5),
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  }),
};
