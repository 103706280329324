/**
 * @copyright @ 2021 Cognition Solutions. All rights reserved.
 * @description  Constants to set the Breakpoints.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created  : 02/Aug/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Constants */

const breakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1920,
    laptop: 1280,
  },
};

export default breakpoints;
