/**
 * @copyright @2022 Jiyana Foundations. All rights reserved.
 * @description Footer Page
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 2/Jan/2023
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React from "react";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

/* Local Imports */
import styles from "./index.style";
import { PAGE_ROOT } from "routes/paths";

function Footer() {
  /* Constants */
  const navItems = [
    // {
    //   name: "Our Work",
    //   link: "/our-work",
    // },
    // {
    //   name: "Why Us",
    //   link: "/why-us",
    // },
    // {
    //   name: "Privacy Policy",
    //   link: "/get-involved",
    // },
    // {
    //   name: "Terms and Conditions",
    //   link: "/resources",
    // },
    {
      name: "Become a Volunteer",
      link: PAGE_ROOT.volunteerForm.absolutePath,
    },
  ];

  const iconItems = [
    {
      icon: <GoogleIcon />,
      href: "#",
    },
    {
      icon: <TwitterIcon />,
      href: "#",
    },
    {
      icon: <LinkedInIcon />,
      href: "#",
    },
    {
      icon: <InstagramIcon />,
      href: "#",
    },
  ];

  /* Output */
  return (
    <>
      <Stack
        sx={styles.footerRootstyles}
        direction="row"
        spacing={{ md: 10, sm: 0 }}
        flexWrap="wrap"
      >
        <Stack spacing={3.75}>
          <Typography variant="h6" sx={styles.mainTitle}>
            Jiyana Foundation
          </Typography>
          <Typography variant="body2" sx={styles.footerSubtitle}>
            As a company registered under Section 8 of Companies Act 2013,
            Jiyana Foundation is governed by dedicated Founders and Board of
            Trustees and managed by an efficient team who work hard to ensure
            that every person is computer literate.
          </Typography>
        </Stack>
        <Stack spacing={3.375} sx={styles.mainMenuStack}>
          <Typography variant="h6" sx={styles.mainMenuTitle}>
            Quick Links
          </Typography>
          <Stack spacing={2}>
            {navItems.map((item, index) => (
              <Link
                href={item.link}
                key={index}
                underline="none"
                sx={styles.navLinks}
              >
                <Typography variant="body2" sx={styles.navItem}>
                  {item.name}
                </Typography>
              </Link>
            ))}
            <Link
              sx={styles.navLinks}
              underline="none"
              href={PAGE_ROOT.termsAndConditions.absolutePath}
            >
              <Typography variant="body2" sx={styles.navItem}>
                Terms and Conditions
              </Typography>
            </Link>
            {/* <Link
              sx={styles.navLinks}
              underline="none"
              href={PAGE_ROOT.privacyPolicy.absolutePath}
            >
              <Typography variant="body2" sx={styles.navItem}>
                Privacy Policy
              </Typography>
            </Link> */}
          </Stack>
        </Stack>

        <Stack>
          <Typography variant="h6" sx={styles.getintouchText}>
            Get in Touch
          </Typography>
          <Typography variant="body2" sx={styles.supportText}>
            Our support available to help you whenever it is needed.
            <br />
            <br />
             E:{' '}
            <a href="mailto:info@jiyanafoundation.org" style={{ textDecoration: 'none',color: "#DADADA" }}>info@jiyanafoundation.org</a>
           
          </Typography>

          {/* <Stack direction="row" spacing={3} sx={styles.iconStack}>
            {iconItems.map((item, index) => (
              <Link key={index} sx={styles.iconBox} href={item.href}>
                {item.icon}
              </Link>
            ))}
          </Stack> */}
        </Stack>
      </Stack>
      <Divider />
      <Box sx={styles.copyrightBox}>
        <Typography variant="body3" sx={{ textAlign: "center" }}>
          © 2023 Jiyana Foundation. All right reserved.
        </Typography>
      </Box>
    </>
  );
}

export default Footer;
