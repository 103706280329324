/* Imports */
import { lazy } from "react";
import AuthLayout from "../layout/AuthLayout";
import UserLayout from "../layout/UserLayout";
import UserGuard from "./guards/UserGuard";

/* Relative Imports */

/* Local Imports */
import { PAGE_AUTH } from "./paths";

// ----------------------------------------------------------------------

/**
 * Auth Module Imports
 */

const SignInPage = lazy(() => import("../views/auth/SignIn"));
const SignUpPage = lazy(() => import("../views/auth/SignUp"));

// ----------------------------------------------------------------------

/**
 * Auth Routes
 */

/**
 * @return {Object}
 */
const AuthRoutes = [
  {
    path: PAGE_AUTH.signIn.absolutePath,
    element: (
      <UserGuard>
        <AuthLayout>
          <SignInPage />
        </AuthLayout>
      </UserGuard>
    ),
  },
  {
    path: PAGE_AUTH.signUp.absolutePath,
    element: (
      <UserGuard>
        <AuthLayout>
          <SignUpPage />
        </AuthLayout>
      </UserGuard>
    ),
  },
];

export default AuthRoutes;
