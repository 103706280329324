/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define the logged in user routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { lazy } from "react";
import VolunteerForm from "../components/VolunteerForm";
import UserLayout from "../layout/UserLayout";

/* Relative Imports */
import { PAGE_ROOT } from "./paths";
import AboutUs from "views/pages/Home/components/AboutUs";

/* Local Imports */

// ----------------------------------------------------------------------

/* User Module Imports */
const Home = lazy(() => import("../views/pages/Home"));
const Ourwork = lazy(() => import("../views/pages/Ourwork"));
const Whyus = lazy(() => import("../views/pages/Whyus"));
const Getinvolved = lazy(() => import("../views/pages/Getinvolved"));
const Resources = lazy(() => import("../views/pages/Resources"));
const BlogsPage = lazy(() =>
  import("views/pages/Resources/components/BlogsPage")
);
const BlogDetailPage = lazy(() =>
  import("views/pages/Resources/components/BlogsPage")
);
const NewsDetail = lazy(() =>
  import("views/pages/Resources/components/NewsDetail")
);
const NewsDetailPage = lazy(() =>
  import("views/pages/Resources/components/NewsDetail")
);
const PrivacyPolicy = lazy(() =>
  import("components/Footer/components/PrivacyPolicy")
);
const PageNotFound = lazy(() => import("views/pages/PageNotFound"));

const TermsAndConditions = lazy(() =>
  import("components/Footer/components/TermsAndConditions")
);
// const UserProfile = lazy(() => import("components/UserProfile"));
// ----------------------------------------------------------------------

/**
 * assign components to routes
 *
 * @return {array}
 */
const userRoutes = [
  {
    path: PAGE_ROOT.home.absolutePath,
    element: (
      <>
        <UserLayout>
          <Home />
        </UserLayout>
      </>
    ),
  },
  {
    path: "#aboutUs",
    element: (
      <>
        <UserLayout>
          <AboutUs />
        </UserLayout>
      </>
    ),
  },
  {
    path: PAGE_ROOT.whyUs.absolutePath,
    element: (
      <>
        <UserLayout>
          <Whyus />
        </UserLayout>
      </>
    ),
  },
  {
    path: PAGE_ROOT.getInvolved.absolutePath,
    element: (
      <>
        <UserLayout>
          <Getinvolved />
        </UserLayout>
      </>
    ),
  },
  {
    path: PAGE_ROOT.blogs.relativePath,
    children: [
      {
        index: true,
        element: (
          <UserLayout>
            <BlogsPage />
          </UserLayout>
        ),
      },
      {
        path: PAGE_ROOT.blogs.detail.relativePath,
        element: (
          <UserLayout>
            <BlogDetailPage />
          </UserLayout>
        ),
      },
    ],
  },
  {
    path: PAGE_ROOT.newsDetail.relativePath,
    children: [
      {
        index: true,
        element: (
          <UserLayout>
            <NewsDetail />
          </UserLayout>
        ),
      },
      {
        path: PAGE_ROOT.newsDetail.detail.relativePath,
        element: (
          <UserLayout>
            <NewsDetailPage />
          </UserLayout>
        ),
      },
    ],
  },
  {
    path: PAGE_ROOT.resources.absolutePath,
    element: (
      <>
        <UserLayout>
          <Resources />
        </UserLayout>
      </>
    ),
  },
  {
    path: PAGE_ROOT.blogs.absolutePath,
    element: (
      <>
        <UserLayout>
          <BlogsPage />
        </UserLayout>
      </>
    ),
  },
  {
    path: PAGE_ROOT.newsDetail.absolutePath,
    element: (
      <>
        <UserLayout>
          <NewsDetail />
        </UserLayout>
      </>
    ),
  },
  {
    path: PAGE_ROOT.volunteerForm.absolutePath,
    element: (
      <>
        <UserLayout>
          <VolunteerForm />
        </UserLayout>
      </>
    ),
  },
  {
    path: PAGE_ROOT.termsAndConditions.absolutePath,
    element: (
      <>
        <UserLayout>
          <TermsAndConditions />
        </UserLayout>
      </>
    ),
  },
  {
    path: PAGE_ROOT.privacyPolicy.absolutePath,
    element: (
      <>
        <UserLayout>
          <PrivacyPolicy />
        </UserLayout>
      </>
    ),
  },
  // {
  //   path: PAGE_ROOT.userProfile.absolutePath,
  //   element: (
  //     <>
  //       <UserLayout>
  //         <UserProfile />
  //       </UserLayout>
  //     </>
  //   ),
  // },
  {
    path: "*",
    element: (
      <>
        <UserLayout>
          <PageNotFound />
        </UserLayout>
      </>
    ),
  },
];

export default userRoutes;
