export default {
  formLabelStyle: (theme) => ({
    position: "relative",
    marginBottom: theme.spacing(0.5),
    width: "100%",
  }),
  formHelperTextStyle: (theme) => ({
    // marginLeft: theme.spacing(0.5),
    // marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2.5),
  }),
};
