/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create layout for admin dashboard pages.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { useState } from "react";
import PropTypes from "prop-types";
import { Box, Stack } from "@mui/material";

/* Local Imports */
import styles from "./index.style";
import Sidebar from "./components/Sidebar";
import { Header } from "./components";

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 */

const propTypes = {
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

/**
 * Outer design layout for all admin dashboard pages
 *
 * @component
 * @param {node} children - contains the child components
 */
function AdminDashboardLayout({ children }) {
  /* States */

  /* Output */

  return (
    <>
      <Box sx={styles.rootStyle}>
        <Header />
        <Sidebar />
        <Box sx={styles.wrapperStyle}>
          <Box sx={styles.containerStyle}>{children}</Box>
        </Box>
      </Box>
    </>
  );
}

AdminDashboardLayout.propTypes = propTypes;
export default AdminDashboardLayout;
