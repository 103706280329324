/**
 * @copyright @ 2021 Cognition Solutions. All rights reserved.
 * @description  Palette theme page to create custom palette colors.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created  : 02/Aug/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { alpha } from "@mui/material";
// import { primaryColorConfig } from "config";

// ----------------------------------------------------------------------

/**
 * To create a linear gradient using the given color parameters
 *
 * @param color1 - color that is used to create linear gradient
 * @param color2 - color that is used to create linear gradient
 * @returns linear-gradient of both the colors
 */
function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

/* Constants */

/* white */
const PRIMARY = {
  main: "#EE4268",
};

/* black1 */
const SECONDARY = {
  main: "#4ECD99",
};

const INFO = {
  lighter: "#7EA0FC",
  light: "#5380FB",
  main: "#2961FB",
  dark: "#0C53B7",
  darker: "#04297A",
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#0CB963",
  dark: "#229A16",
  darker: "#08660D",
};

const WARNING = {
  lighter: "#BABABA",
  light: "#4B4B55",
  main: "#FDD141",
  dark: "#B78103",
  darker: "#7A4F01",
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF3434",
  dark: "#B72136",
  darker: "#7A0C2E",
};
const GREY = {
  50: "#FAFAFA",
  100: "#EDEDED",
  200: "#DADADA",
  300: "#B1B1B1",
  400: "#A9A9A9",
  500: "#767676",
  600: "#616161",
  700: "#5F5F5F",
  800: "#4B4B4B",
  850: "#88859c ",
  900: "#3F3A47",
  950: "#718096",
  1000: "#000000",
  500_8: alpha("#BABABA", 0.08),
  500_12: alpha("#BABABA", 0.12),
  500_16: alpha("#BABABA", 0.16),
  500_24: alpha("#BABABA", 0.24),
  500_32: alpha("#BABABA", 0.32),
  500_48: alpha("#BABABA", 0.48),
  500_56: alpha("#BABABA", 0.56),
  500_80: alpha("#BABABA", 0.8),
};

/* 
#718096
#88859C
#454545
#3F3A47
#2D3748
#434343
#000000
#21202B
#262626
#2C2C2C
#393939
#616161
#626262
#555555
#535353
#4B4B4B
#5F5F5F
#767676
#878787
#747474
#D3D6D8
#D9D9D9
#FFFFFF
*/

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
  info: { ...INFO, contrastText: "#fff" },
  success: { ...SUCCESS, contrastText: "#fff" },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: "#fff" },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[300],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500],
    disabledBackground: GREY[300],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    text: {
      primary: GREY[800],
      secondary: GREY[500],
      disabled: GREY[300],
    },
    background: { paper: "#D9D9D9", default: "#FFFFFF", neutral: "#21202B" },
    action: { active: "#2C274E", ...COMMON.action },
  },
};

export default palette;
