/**
 * @copyright @ 2021 Cognition Solutions. All rights reserved.
 * @description Common Input field for Text.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created  : 03/Aug/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";

/* local Imports */
import styles from "./index.style";

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {string} name
 * @property {string} label
 * @property {string} size
 * @property {boolean} required
 * @property {boolean} error
 * @property {string} helperText
 */
const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

/**
 * @typedef defaultProps
 * @property {string} label
 * @property {string} size
 * @property {boolean} required
 * @property {boolean} error
 * @property {string} helperText
 */
const defaultProps = {
  label: "",
  size: "large",
  required: false,
  error: false,
  helperText: "",
};

// ----------------------------------------------------------------------

/**
 * Handles text from Input
 *
 * @Components
 * @param {string} name - name for input
 * @param {string} label - label text for the input
 * @param {string} size - size for the input form
 * @param {boolean} required - to indicate input is required or not
 * @param {boolean} error - contains error message
 * @param {string} helperText - it shows the hints
 *
 * @returns {JSX.Element}
 */
function TextInput({
  name,
  label,
  width,
  size,
  required,
  defaultValue,
  color,
  error,
  helperText,
  maxRows,
  rows,
  ...other
}) {
  /* Output */
  return (
    <>
      {/* <FormControl fullWidth required={required} size={size} error={error}>
        {label && (
          <FormLabel sx={styles.formLabelStyle} htmlFor={name}>
            {label}
          </FormLabel>
        )}
        <OutlinedInput fullWidth id={name} name={name} {...other} />
        {error && helperText && (
          <FormHelperText sx={styles.formHelperTextStyle} error>
            {helperText}
          </FormHelperText>
        )}
      </FormControl> */}

      <FormControl
        fullWidth
        // width={width}
        required={required}
        size={size}
        error={error}
        sx={{ width: width }}
      >
        <TextField
          fullWidth
          multiline
          maxRows={maxRows}
          rows={rows}
          id={name}
          name={name}
          defaultValue={defaultValue}
          label={label}
          color={color}
          {...other}
        />
        {error && helperText && (
          <FormHelperText sx={styles.formHelperTextStyle} error>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
}

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;
export default memo(TextInput);
