/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define all the paths.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Constants */
const ROOT_PATH = "/";
const ROOT_ADMIN_DASHBOARD = "admin-dashboard";

/* Home Page */
export { ROOT_PATH };

/* Root Pages */

export const PAGE_AUTH = {
  signIn: {
    relativePath: "signin",
    absolutePath: "/sign-in",
  },
  signUp: {
    relativePath: "signup",
    absolutePath: "/sign-up",
  },
  forgotPassword: {
    relativePath: "forgot-password",
    absolutePath: "/forgot-password",
  },
  resetPassword: {
    relativePath: "reset-password",
    absolutePath: "/reset-password",
  },
};
export const PAGE_ROOT = {
  home: {
    relativePath: "home",
    absolutePath: "/",
  },
  ourWork: {
    relativePath: "our-work",
    absolutePath: "/our-work",
  },
  whyUs: {
    relativePath: "why-us",
    absolutePath: "/why-us",
  },
  getInvolved: {
    relativePath: "get-involved",
    absolutePath: "/get-involved",
  },
  resources: {
    relativePath: "resources",
    absolutePath: "/resources",
  },
  termsAndConditions: {
    relativePath: "terms-and-conditions",
    absolutePath: "/terms-and-conditions",
  },
  privacyPolicy: {
    relativePath: "privacy-policy",
    absolutePath: "/privacy-policy",
  },
  donate: {
    relativePath: "donate",
    absolutePath: `/donate`,
  },

  blogs: {
    relativePath: "blogs",
    absolutePath: "/blogs",
    detail: {
      relativePath: ":id",
      absolutePath: "/blogs/:id",
    },
  },
  newsDetail: {
    relativePath: "news-detail",
    absolutePath: "/news-detail",
    detail: {
      relativePath: ":id",
      absolutePath: "/news-detail/:id",
    },
  },
  volunteerForm: {
    relative: "become-a-volunteer  ",
    absolutePath: "/become-a-volunteer",
  },
  userProfile: {
    relative: "user-profile",
    absolutePath: "/userProfile",
  },
};
/* Admin Dashboard Pages */
export const PAGE_ADMIN_DASHBOARD = {
  root: {
    relativePath: ROOT_ADMIN_DASHBOARD,
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}`,
  },
  blogs: {
    relativePath: "blogs",
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/blogs`,
    create: {
      relativePath: "create",
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/blogs/create`,
    },
    edit: {
      relativePath: "edit/:id",
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/blogs/edit/:id`,
    },
  },
  categories: {
    relativePath: "categories",
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/categories`,
    create: {
      relativePath: "create",
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/categories/create`,
    },
    edit: {
      relativePath: "edit/:id",
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/categories/edit/:id`,
    },
  },
  news: {
    relativePath: "news",
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/news`,
    create: {
      relativePath: "create",
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/news/create`,
    },
    edit: {
      relativePath: "edit/:id",
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/news/edit/:id`,
    },
  },
};
