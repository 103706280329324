/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create styles for account popover component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

export default {
  accountPopover: (theme) => ({
    minWidth: 300,
    backgroundColor: theme.palette.background.default,
  }),
  popoverProfile: (theme) => ({
    my: 1.5,
    px: 2.5,
    flexWrap: "nowrap",
  }),

  popoverMenuItem: (theme) => ({
    py: 1,
    px: 2.5,
    typography: "body2",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  }),
  modeMenuItem: {
    cursor: "default",
    "&:hover": {
      background: "none",
    },
  },
  profileDivider: {
    my: 1,
  },
  logoutDivider: {
    marginBottom: "0px !important",
  },
  logout: {
    width: "100%",
    justifyContent: "center",
  },
  profilePicture: {
    height: 36,
    width: 36,
  },
  adminDashboardButton: (theme) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
    marginTop: theme.spacing(2),
  }),
};
