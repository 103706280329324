/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create layout for auth pages.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

/* Relative Imports */
import Footer from "../../components/Footer";

/* Local Imports */
import styles from "./index.style";
import Header from "../../components/Header";

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

/**
 * Outer design layout for all auth pages
 *
 * @component
 * @param {node} children - contains the child components
 */
function AuthLayout({ children }) {
  /* Output */
  return (
    <Box>
      <Header />
      <Box sx={styles.containerStyle}>{children}</Box>

      <Footer />
    </Box>
  );
}

AuthLayout.propTypes = propTypes;
export default AuthLayout;
