/**
 * @copyright @ 2021 Cognition Solutions. All rights reserved.
 * @description Reducer for Sign In page.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created  : 16/Aug/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import {
  SIGNIN_EMAIL_OTP_REQUEST,
  SIGNIN_EMAIL_OTP_VERIFY,
  SIGNIN_FAILURE,
  SIGNIN_PASSWORD_REQUEST,
  SIGNIN_RESEND_EMAIL_OTP_REQUEST,
  SIGNIN_RESEND_SMS_OTP_REQUEST,
  SIGNIN_RESET,
  SIGNIN_SMS_OTP_REQUEST,
  SIGNIN_SMS_OTP_VERIFY,
  SIGNIN_SUCCESS,
} from "../../actions";

// ----------------------------------------------------------------------

/** Initial State */

const signInInitialState = {
  loading: false,
  loadingResend: false,
  success: null,
  failure: null,
};

/**
 * Reducer method for particular action
 * @param {*} state initial state
 * @param {*} action particular action
 * @returns updated state
 */
export const signIn = (state = signInInitialState, action = {}) => {
  switch (action.type) {
    case SIGNIN_PASSWORD_REQUEST:
    case SIGNIN_EMAIL_OTP_REQUEST:
    case SIGNIN_EMAIL_OTP_VERIFY:
    case SIGNIN_SMS_OTP_REQUEST:
    case SIGNIN_SMS_OTP_VERIFY:
      return { ...state, loading: true };
    case SIGNIN_RESEND_EMAIL_OTP_REQUEST:
    case SIGNIN_RESEND_SMS_OTP_REQUEST:
      return { ...state, loadingResend: true };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingResend: false,
        success: action.payload,
        failure: null,
      };
    case SIGNIN_FAILURE:
      return {
        ...state,
        loading: false,
        loadingResend: false,
        success: null,
        failure: action.payload,
      };
    case SIGNIN_RESET:
      return {
        ...state,
        loading: false,
        loadingResend: false,
        success: null,
        failure: null,
      };
    default:
      return state;
  }
};
