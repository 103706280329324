import { Box, Hidden, Stack, Typography } from "@mui/material";
import React from "react";
import { aboutUsData } from "constants/appConstant";
import styles from "./index.style.js";
function AboutUs() {
  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={styles.rootStyles}
        spacing={4}
      >
        <Typography variant="h2" sx={styles.mainTitle}>
          {aboutUsData.title}
        </Typography>

        <Stack
          direction={{ md: "row", sm: "column" }}
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={styles.mobileImg}>
            <img
              src={aboutUsData.image}
              alt="image"
              style={{
                maxHeight: "415px",
                maxWidth: "539px",
                width: "100%",
              }}
            />
          </Box>
          <Box sx={styles.aboutUsDescription}>
            {aboutUsData.description.map((desc) => (
              <Typography variant="body3" sx={styles.description}>
                <ul style={{ padding: 0, margin: 0 }}>{desc}</ul>
              </Typography>
            ))}
          </Box>
        </Stack>
      </Stack>
    </>
  );
}

export default AboutUs;
