/**
 * @copyright @2022 Jiyana Foundations. All rights reserved.
 * @description Hamburger Menu
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 2/Jan/2023
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React, { memo, useEffect, useState } from "react";
import { globalHistory } from "@reach/router";

import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  SwipeableDrawer,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

/* local Imports */
import styles from "./index.style";
import { useSelector } from "react-redux";
import AccountPopover from "components/AccountPopover";
import { PAGE_ROOT } from "routes/paths";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

/**
 * Hamburger Menu inside the header for mobile screen
 *
 * @component
 */

function HamburgerMenu() {
  /* Constants */

  const pathName = globalHistory.location.pathname;

  const navItems = [
    {
      name: "About Us",
      link: "aboutUs",
    },
    {
      name: "Our goal",
      link: "ourGoal",
    },
    {
      name: "Our work",
      link: "ourWork",
    },
  ];
  /* Hooks */

  /* Constants */
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  /* States */
  const [openHamburger, setOpenHamburger] = useState(false);

  /* Functions */
  const handleNavLinkClick = (event, anchorId) => {
    const targetElement = document.querySelector(anchorId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  /* Output */
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={styles.hamburgerStack}
      >
        <a href="/">
          <img
            src={"/assets/images/logo_jiyana.png"}
            alt="logo"
            style={{ maxWidth: "120px", maxHeight: "120px", width: "100%" }}
          />
        </a>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* {isAuthenticated && <AccountPopover userData={user} />} */}
          <IconButton
            onClick={() => setOpenHamburger(true)}
            sx={styles.hamburgericon}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Stack>
      <SwipeableDrawer
        anchor="right"
        open={openHamburger}
        onOpen={() => setOpenHamburger(true)}
        onClose={() => setOpenHamburger(false)}
        sx={styles.Drawer}
      >
        <>
          <Box sx={styles.crossButtonBox}>
            <IconButton
              onClick={() => setOpenHamburger(false)}
              sx={styles.crossButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <List sx={styles.menuStyle}>
            {navItems.map((item, index) => (
              <ListItemButton
                key={index}
                href={`/#${item.link.replace("/", "")}`}
                onClick={(event) => {
                   setOpenHamburger(false);
                  handleNavLinkClick(event, `/#${item.link.replace("/", "")}`);
                 
                }}
                selected={pathName.includes(item.link)}
              >
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{ variant: "h6" }}
                />
              </ListItemButton>
            ))}
            {/* {!isAuthenticated && ( */}
            <Button
              sx={styles.donateButton}
              onClick={() => {
                window.location.href = PAGE_ROOT.volunteerForm.absolutePath;
              }}
            >
              <Typography variant="button">Become a Volunteer</Typography>
            </Button>

            {/* )} */}
          </List>
        </>
      </SwipeableDrawer>
    </>
  );
}
export default memo(HamburgerMenu);
