/* Imports */
import { call, put, select } from "redux-saga/effects";
import jwt from "jwt-decode";

// ----------------------------------------------------------------------
/* Relative Imports */
import { getAccessToken, getAccessTokenStorage } from "helper/authHelper";
import {
  getUserByIdRequest,
  loginUserRequest,
  registerUserRequest,
} from "services/auth";

/* Local Imports */
import { setLoginAction, setLogoutAction } from "../actions/auth/authAction";
import {
  signInFailureAction,
  signInSuccessAction,
} from "../actions/auth/signInAction";

// ----------------------------------------------------------------------

const commonErrorMessage = "Unable to process your request. Please try again";
const sendOtpErrorMessage = "Unable to send OTP. Please try again.";
const sendEmailOtpMessage = "OTP sent to your registered email address.";
const sendSmsOtpMessage = "OTP sent to your registered mobile number.";
const invalidOtpErrorMessage =
  "OTP is either Invalid or Expired. Please enter correct one or resend it.";

const sendOtpRequest = {
  otpLength: "6",
  emailTemplate: "register-mobile",
  productVariant: "Cognition",
  subject: "[Cognition] Verification Code for Mobile Registration",
  otpExpiredSeconds: "600",
};

const verifyOtpRequest = {
  emailTemplate: "register-mobile",
  productVariant: "Cognition",
  otpExpiredSeconds: "600",
};

/**
 * Saga function which to check if the accessToken is valid or not
 * @param accessToken - jwt token
 */
function isValidToken(accessToken) {
  if (!accessToken) {
    return false;
  }
  const decoded = jwt(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime ? decoded : null;
}

/**
 * Saga function to initializeAuth using accessToken
 */
export function* initializeAuth() {
  try {
    const accessToken = getAccessToken();
    const user = isValidToken(accessToken);
    if (accessToken && user) {
      yield put(
        setLoginAction({
          token: accessToken,
          user,
          rememberMe: getAccessTokenStorage(),
        })
      );
    } else {
      yield put(setLogoutAction());
    }
  } catch {
    yield put(setLogoutAction());
  }
}

/**
 * Saga function which calls api for user to Sign In with Password
 * @param action with payload (user name and password)
 */
export function* signInWithPassword(action) {
  try {
    const reqData = {
      username: action.payload.username,
      password: action.payload.password,
    };
    const response = yield call(loginUserRequest, reqData);
    if (response?.Token) {
      const jwtToken = response.Token.Token;

      const user = jwt(jwtToken);

      yield put(
        setLoginAction({
          token: jwtToken,
          user,
          // rememberMe: action.payload.rememberMe,
        })
      );
      yield put(
        signInSuccessAction({
          statusCode: 200,
          message: "SignIn Successful",
        })
      );
    } else {
      yield put(
        signInFailureAction({
          statusCode: 500,
          message: commonErrorMessage,
        })
      );
    }
  } catch (error) {
    if (error.response?.data?.statusCode === 401) {
      yield put(
        signInFailureAction({
          statusCode: 400,
          message:
            "Invalid email/password. Please enter your registered email and password.",
        })
      );
    } else {
      yield put(
        signInFailureAction({
          statusCode: 500,
          message: commonErrorMessage,
        })
      );
    }
  }
}

// export function* signUpwithPassword(action) {
//   try{
//     const reData = {
//       fullName = action.payload.fullName,
//       age=action.payload.age,
//       phoneNumber=action.payload.phoneNumber,
//       emailAddress=action.payload.emailAddress,
//       gender=action.payload.gender,
//       datesAvailable=action.payload.datesAvailable,
//       hoursAvailable=action.payload.hoursAvailable,
//       whyInterested=action.payload.whyInterested,
//       previousVolunteering=action.payload.previousVolunteering,
//       name_email_references=action.payload.name_email_references,
//       emergency_contact=action.payload.emergency_contact

//     };
//     const response = yield call(registerUserRequest, reqData);
//     if(response.)
//   }
// }
