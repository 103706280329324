/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create routing from all the routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { Suspense, useState } from "react";
import { useParams, useRoutes } from "react-router-dom";

/* Relative Imports */
/* Local Imports */
import userRoutes from "./userRoutes";
import AuthRoutes from "./authRoutes";
import LoadingScreen from "components/LoadingScreen";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import AdminDashboardRoutes from "./adminDashboardRoutes";

// ----------------------------------------------------------------------
/* Merge Routes */
const routes = [...AuthRoutes, ...userRoutes];

/**
 * Create routing with the routes
 *
 * @return {JSX.Element}
 */

function Routing() {
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const content = useRoutes(routes);

  useEffect(() => {
    if (isAuthenticated && user) {
      if (user?.Role && user?.Role?.RoleName === "admin") {
        routes.push(...AdminDashboardRoutes);
      }
    }
  }, [isAuthenticated, user?.Role]);

  return <Suspense fallback={<LoadingScreen />}>{content}</Suspense>;
}

export default Routing;
