/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create website logo component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Link } from "@mui/material";

/* Relative Imports */
import { ROOT_PATH } from "../../routes/paths";

/* Local Imports */
import styles from "./index.style";

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {boolean} isIcon
 */
const propTypes = {
  isIcon: PropTypes.bool,
};

/**
 * @typedef defaultProps
 * @property {boolean} isIcon
 */
const defaultProps = {
  isIcon: false,
};

// ----------------------------------------------------------------------

/**
 * styling the logo for Website.
 *
 * @component
 * @param {boolean} isIcon - flag to show original logo or icon
 * @return {JSX.Element}
 */
function WebsiteLogo({ isIcon, ...other }) {
  /* Output */
  return (
    <Link
      component={RouterLink}
      underline="none"
      to={ROOT_PATH}
      sx={styles.logoLink}
    >
      <Box
        component="img"
        alt="logo"
        src={"/assets/images/logo_jiyana.png"}
        sx={styles.logo}
        {...other}
      />
    </Link>
  );
}

WebsiteLogo.propTypes = propTypes;
WebsiteLogo.defaultProps = defaultProps;
export default memo(WebsiteLogo);
