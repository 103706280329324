/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create styles for auth layout.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

export default {
  containerStyle: (theme) => ({
    flex: 1,
    width: "100%",
    marginTop: "104px",
    marginBottom: "104px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10),
    },
  }),
};
