/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create Account popover component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

/* Relative Imports */

/* Local Imports */
import styles from "./index.style";
import MenuPopover from "components/MenuPopover";
import { ConfirmDialog } from "components/Dialog";
import MyAvatar from "components/MyAvatar";
import { useDispatch, useSelector } from "react-redux";
import { setLogoutAction } from "store/actions/auth/authAction";
import { PAGE_ADMIN_DASHBOARD, PAGE_ROOT } from "routes/paths";
import { navigate } from "@reach/router";

// ----------------------------------------------------------------------

/**
 * Account/My Profile Popover for the logged in pages
 *
 * @component
 * @return {JSX.Element}
 */
function AccountPopover({ userData }) {
  /* Hooks */

  /* States */
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  /* Constants */
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  /**
   * function to open profile menu
   * @return {void}
   */
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * function to close profile menu
   * @return {void}
   */
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  /**
   * function to open logout confirmation dialog
   * @return {void}
   */
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  /**
   * function to close logout confirmation dialog
   * @return {void}
   */
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  /**
   * function to call on cancel logout
   * @return {void}
   */
  const handleLogout = () => {
    dispatch(setLogoutAction());
    navigate(PAGE_ROOT.home.absolutePath);
  };
  /**
   * function to call on cancel logout
   * @return {void}
   */
  const handleLogoutCancel = () => {
    handleDialogClose();
    handleCloseMenu();
  };

  return (
    <>
      <>
        <IconButton onClick={handleOpenMenu}>
          {(userData?.userProfileImage && (
            <Avatar
              sx={styles.profilePicture}
              src={userData.userProfileImage}
            />
          )) || <AccountCircleIcon />}
        </IconButton>
        <MenuPopover
          id="logout"
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          contentStyle={styles.accountPopover}
        >
          <Box sx={styles.popoverProfile}>
            <Typography variant="subtitle1">{userData?.FullName}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {userData?.Email}
            </Typography>
            {userData?.Role && userData?.Role?.RoleName === "admin" && (
              <Button
                sx={styles.adminDashboardButton}
                href={PAGE_ADMIN_DASHBOARD.categories.absolutePath}
              >
                <Typography variant="body2">Admin Dashboard</Typography>
              </Button>
            )}
          </Box>
          <Divider sx={styles.profileDivider} />

          <Divider sx={styles.logoutDivider} />
          <MenuItem
            component={Button}
            onClick={handleDialogOpen}
            sx={[styles.popoverMenuItem, styles.logout]}
          >
            Logout
          </MenuItem>
        </MenuPopover>
        <ConfirmDialog
          open={openDialog}
          description="Are you sure you want to Logout?"
          agreeText="Logout"
          disagreeText="Cancel"
          onAgreeAction={handleLogout}
          onDisAgreeAction={handleLogoutCancel}
        />
      </>
    </>
  );
}

export default memo(AccountPopover);
