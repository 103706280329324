/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create layout for logged in user pages.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import PropTypes from "prop-types";
import { Box } from "@mui/material";

/* Relative Imports */
import Header from "../../components/Header";
import Footer from "../../components/Footer";

/* Local Imports */
import styles from "./index.style";
import { getUserProfileAction } from "store/actions/user/userProfileAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

/**
 * Outer design layout for logged in user pages
 *
 * @component
 * @param {node} children - contains the child components
 */
function UserLayout({ children }) {
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  /* Side-Effects */
  useEffect(() => {
    if (isAuthenticated && user?.Id) {
      dispatch(getUserProfileAction(user.Id));
    }
  }, [isAuthenticated]);
  /* Output */
  return (
    <Box>
      <Header />
      <Box>{children}</Box>
      <Footer />
    </Box>
  );
}

UserLayout.propTypes = propTypes;
export default UserLayout;
