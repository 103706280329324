/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define the root routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";

/* Relative Imports */
import AdminDashboardLayout from "../layout/AdminDashboardLayout";
import AuthGuard from "./guards/AuthGuard";

/* Local Imports */
import { PAGE_ADMIN_DASHBOARD } from "./paths";

// ----------------------------------------------------------------------

/* Admin Dashboard Module Imports */

const CreateBlogs = lazy(() =>
  import("views/admin-dashboard/blogs/CreateBlogs")
);

const ManageBlogs = lazy(() =>
  import("views/admin-dashboard/blogs/ManageBlogs")
);

const CreateCategoryPage = lazy(() =>
  import("views/admin-dashboard/category/CreateCategory")
);

const ManageCategoryPage = lazy(() =>
  import("views/admin-dashboard/category/ManageCategory")
);

const ManageNewsPage = lazy(() =>
  import("views/admin-dashboard/news/ManageNews")
);

const CreateNewsPage = lazy(() =>
  import("views/admin-dashboard/news/CreateNews")
);

// ----------------------------------------------------------------------

/**
 * assign components to routes
 *
 * @return {array}
 */
const AdminDashboardRoutes = [
  {
    path: PAGE_ADMIN_DASHBOARD.root.relativePath,
    element: (
      <AuthGuard>
        <AdminDashboardLayout>
          <Outlet />
        </AdminDashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={PAGE_ADMIN_DASHBOARD.blogs.absolutePath} />,
      },
      {
        path: PAGE_ADMIN_DASHBOARD.blogs.absolutePath,
        children: [
          {
            index: true,
            element: <ManageBlogs />,
          },
          {
            path: PAGE_ADMIN_DASHBOARD.blogs.create.absolutePath,
            element: <CreateBlogs />,
          },
          {
            path: PAGE_ADMIN_DASHBOARD.blogs.edit.relativePath,
            element: <CreateBlogs />,
          },
        ],
      },
      {
        path: PAGE_ADMIN_DASHBOARD.categories.relativePath,
        element: <ManageCategoryPage />,
      },
      {
        path: PAGE_ADMIN_DASHBOARD.categories.create.absolutePath,
        element: <CreateCategoryPage />,
      },
      {
        path: PAGE_ADMIN_DASHBOARD.categories.edit.absolutePath,
        element: <CreateCategoryPage />,
      },
      {
        path: PAGE_ADMIN_DASHBOARD.news.relativePath,
        element: <ManageNewsPage />,
      },
      {
        path: PAGE_ADMIN_DASHBOARD.news.create.absolutePath,
        element: <CreateNewsPage />,
      },
      {
        path: PAGE_ADMIN_DASHBOARD.news.edit.absolutePath,
        element: <CreateNewsPage />,
      },
    ],
  },
];

export default AdminDashboardRoutes;
