/* Imports */
import { call, put, select } from "redux-saga/effects";

// ----------------------------------------------------------------------
/* Relative Imports */
import { getUserByIdRequest } from "services/auth";

/* Local Imports */
import {
  setUserProfileAction,
  userProfileFailureAction,
} from "store/actions/user/userProfileAction";
const commonErrorMessage = "Unable to process your request. Please try again";

export function* getUserProfile(action) {
  try {
    const reqData = action.payload;
    const response = yield call(getUserByIdRequest, reqData);
    if (response?.User) {
      yield put(
        setUserProfileAction({
          Role: response.User?.Role,
        })
      );
    } else {
      yield put(
        userProfileFailureAction({
          statusCode: 500,
          message: commonErrorMessage,
        })
      );
    }
  } catch (error) {
    yield put(
      userProfileFailureAction({
        statusCode: 500,
        message: commonErrorMessage,
      })
    );
  }
}
