import { takeEvery } from "redux-saga/effects";
import {
  FORGOT_PASSWORD_REQUEST,
  GET_AUTH_DETAIL,
  GET_USER_PROFILE_REQUEST,
  RESET_PASSWORD_REQUEST,
  SEND_EMAIL_NOTIFICATION,
  SIGNIN_EMAIL_OTP_REQUEST,
  SIGNIN_EMAIL_OTP_VERIFY,
  SIGNIN_PASSWORD_REQUEST,
  SIGNIN_RESEND_EMAIL_OTP_REQUEST,
  SIGNIN_RESEND_SMS_OTP_REQUEST,
  SIGNIN_SMS_OTP_REQUEST,
  SIGNIN_SMS_OTP_VERIFY,
  SIGNUP_EMAIL_OTP_REQUEST,
  SIGNUP_EMAIL_OTP_VERIFY,
  SIGNUP_RESEND_EMAIL_OTP_REQUEST,
  SIGNUP_UPDATE_MOBILE_REQUEST,
} from "../actions";
import { initializeAuth, signInWithPassword } from "./authSaga";
import { getUserProfile } from "./userSaga";

/**
 * A root for all sagas
 */
function* rootSaga() {
  // yield takeEvery(GET_COUNTRY_LIST, getCountries);
  // yield takeEvery(GET_LOCATION_COUNTRY_CODE, getLocationCountryCode);
  // yield takeEvery(GET_USERS_LIST, getUsers);
  yield takeEvery(GET_AUTH_DETAIL, initializeAuth);
  yield takeEvery(SIGNIN_PASSWORD_REQUEST, signInWithPassword);
  // yield takeEvery(SIGNIN_EMAIL_OTP_REQUEST, signInSendEmailOtp);
  // yield takeEvery(SIGNIN_EMAIL_OTP_VERIFY, signInVerifyEmailOtp);
  // yield takeEvery(SIGNIN_SMS_OTP_REQUEST, signInSendSmsOtp);
  // yield takeEvery(SIGNIN_SMS_OTP_VERIFY, signInVerifySmsOtp);
  // yield takeEvery(SIGNUP_EMAIL_OTP_REQUEST, signUpSendEmailOtp);
  // yield takeEvery(SIGNUP_EMAIL_OTP_VERIFY, signUpVerifyEmailOtp);
  // yield takeEvery(SIGNUP_UPDATE_MOBILE_REQUEST, signUpUpdateUserMobile);
  // yield takeEvery(FORGOT_PASSWORD_REQUEST, sendForgotPasswordLink);
  // yield takeEvery(RESET_PASSWORD_REQUEST, resetUserPassword);
  // yield takeEvery(SIGNIN_RESEND_EMAIL_OTP_REQUEST, signInResendEmailOtp);
  // yield takeEvery(SIGNIN_RESEND_SMS_OTP_REQUEST, signInResendSmsOtp);
  // yield takeEvery(SIGNUP_RESEND_EMAIL_OTP_REQUEST, signUpResendEmailOtp);
  yield takeEvery(GET_USER_PROFILE_REQUEST, getUserProfile);
  // yield takeEvery(SEND_EMAIL_NOTIFICATION, sendEmailNotification);
}

export default rootSaga;
