/* Imports */
import { setAccessToken } from "helper/authHelper";
import { SET_AUTH_DETAIL, SET_USER_PROFILE } from "../../actions";

// ----------------------------------------------------------------------

/** Initial State */
const authInitialState = {
  loading: false,
  isAuthenticated: false,
  token: null,
  user: null,
};

/**
 * Reducer method for particular action
 * @param {*} state initial state
 * @param {*} action particular action
 * @returns updated state
 */
export const auth = (state = authInitialState, action = {}) => {
  switch (action.type) {
    case SET_AUTH_DETAIL:
      setAccessToken(action.payload.rememberMe || false, action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        user: action.payload.user,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    default:
      return state;
  }
};
