/**
 * @copyright @ 2021 Cognition Solutions. All rights reserved.
 * @description Reducer for Common related Notification.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created  : 14/Sep/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAILURE,
  NOTIFICATION_RESET,
  SEND_EMAIL_NOTIFICATION,
} from "../../actions";

// ----------------------------------------------------------------------

/** Initial State */
const commonNotificationInitialState = {
  loading: false,
  success: null,
  failure: null,
};

/**
 * Reducer method for particular action
 * @param {*} state initial state
 * @param {*} action particular action
 * @returns updated state
 */
export const commonNotification = (
  state = commonNotificationInitialState,
  action = {}
) => {
  switch (action.type) {
    case SEND_EMAIL_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };
    case NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        failure: action.payload,
      };
    case NOTIFICATION_RESET:
      return commonNotificationInitialState;
    default:
      return state;
  }
};
