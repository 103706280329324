/**
 * @copyright @ 2021 Cognition Solutions. All rights reserved.
 * @description Common Input field for Search.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created  : 03/Aug/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField } from "@mui/material";

/* Relative Imports */
import SearchIcon from "@mui/icons-material/Search";

/* local Imports */
import styles from "./index.style";

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {string} variant
 * @property {string} size
 * @property {string} placeholder
 * @property {string} value
 * @property {function} onValueChange
 * @property {function} onSearch
 * @property {array} style
 */
const propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  style: PropTypes.array,
};

/**
 * @typedef defaultProps
 * @property {string} variant
 * @property {string} size
 * @property {string} placeholder
 * @property {function} onSearch
 * @property {array} style
 */
const defaultProps = {
  variant: "standard",
  size: "small",
  placeholder: "Search",
  onSearch: () => null,
  style: [],
};

// ----------------------------------------------------------------------

/**
 * Handles text from Input
 *
 * @Components
 * @param {string} variant
 * @param {string} size
 * @param {string} placeholder
 * @param {string} value
 * @param {function} onValueChange
 * @param {function} onSearch
 * @param {array} style
 *
 * @returns {JSX.Element}
 */
function SearchInput({
  variant = "standard",
  size = "small",
  placeholder = "Search",
  value,
  onValueChange,
  onSearch,
  style = [],
  ...other
}) {
  /**
   * function is called whenever key is pressed
   * @returns {void}
   */
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onSearch();
    }
  };

  /* Output */
  return (
    <TextField
      //   fullWidth
      variant={variant}
      size={size}
      placeholder={placeholder}
      value={value}
      onChange={onValueChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={styles.searchField}
      {...other}
    />
  );
}

SearchInput.propTypes = propTypes;
SearchInput.defaultProps = defaultProps;
export default memo(SearchInput);
