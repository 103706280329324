// ----------------------------------------------------------------------

/* Imports */
import { GET_AUTH_DETAIL, REMOVE_AUTH_DETAIL, SET_AUTH_DETAIL } from "..";

// ----------------------------------------------------------------------

/**
 * Returns the action for getAuthAction
 * @returns Type
 */
export const getAuthAction = () => {
  return {
    type: GET_AUTH_DETAIL,
  };
};

/**
 * Returns the action for setLoginAction
 * @param email - user email address
 * @param password - user password
 * @returns Type and payload
 */
export const setLoginAction = (data) => {
  return {
    type: SET_AUTH_DETAIL,
    payload: data,
  };
};

/**
 * Returns the action for setLogoutAction
 * @param data - user token
 * @returns Type and payload
 */
export const setLogoutAction = () => {
  return {
    type: REMOVE_AUTH_DETAIL,
  };
};
