/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define the services related to user.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */

/* Relative Imports */
import axiosInstance from "../config/axiosConfig";

// ----------------------------------------------------------------------

export const registerUserRequest = (reqData) => {
  return axiosInstance("multipart/form-data", true)
    .post("/auth/RegisterUser", reqData)
    .then((response) => response.data);
};

export const loginUserRequest = (reqData) => {
  return axiosInstance(undefined, true)
    .post("/auth/LoginUser", {
      Email: reqData.username,
      Password: reqData.password,
    })
    .then((response) => response.data);
};

export const getUserByIdRequest = (userId) => {
  return axiosInstance()
    .get(`/admin/user/GetUserById/${userId}`)
    .then((response) => response.data);
};
