import axios from "axios";
import { getAccessToken } from "helper/authHelper";
/* Local Imports */
import { apiBaseUrl, apiAccessToken } from "./config";
// ----------------------------------------------------------------------
export function axiosInstance(contentType, isStaticToken = false) {
  if (contentType === undefined) {
    contentType = "application/json";
  }
  const axiosConfig = axios.create({
    baseURL: apiBaseUrl,

    headers: {
      "Content-Type": contentType,
      "Cache-Control": "no-cache",
    },
  });
  axiosConfig.interceptors.request.use(
    (config) => {
      const accessToken = isStaticToken ? apiAccessToken : getAccessToken();
      console.log(isStaticToken, accessToken, "subs");

      if (accessToken) config.headers.authorization = `bearer ${accessToken}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return axiosConfig;
}
export default axiosInstance;
