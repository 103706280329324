/**
 * Imports
 */
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

/**
 * Relative Imports
 */
import { PAGE_AUTH, PAGE_ROOT } from "routes/paths";

// ----------------------------------------------------------------------

const propTypes = {
  children: PropTypes.element.isRequired,
};

// ----------------------------------------------------------------------

/**
 * @return {JSX.Element}
 */
function AuthGuard({ children }) {
  /* Hooks */
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();

  /* Output */
  if (!isAuthenticated) {
    return (
      <Navigate
        to={`${PAGE_AUTH.signIn.absolutePath}?returnurl=${location.pathname}`}
      />
    );
  }

  return children;
}

AuthGuard.propTypes = propTypes;
export default AuthGuard;
