/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create confirmation dialog component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {boolean} open
 * @property {string|node} description
 * @property {boolean} isSubmitting
 * @property {string} agreeText
 * @property {string} disagreeText
 * @property {function} onAgreeAction
 * @property {function} onDisAgreeAction
 */
const propTypes = {
  open: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  isSubmitting: PropTypes.bool,
  agreeText: PropTypes.string,
  disagreeText: PropTypes.string,
  onAgreeAction: PropTypes.func.isRequired,
  onDisAgreeAction: PropTypes.func.isRequired,
};

/**
 * @typedef defaultProps
 * @property {boolean} open
 * @property {boolean} isSubmitting
 * @property {string} agreeText
 * @property {string} disagreeText
 */
const defaultProps = {
  open: false,
  isSubmitting: false,
  agreeText: "Agree",
  disagreeText: "Disagree",
};

// ----------------------------------------------------------------------

/**
 * Popup dialog component for confirmation message
 *
 * @component
 * @param {boolean} open - to open and close the dialog
 * @param {string|node} description - describing the confirmation message
 * @param {boolean} isSubmitting - to show the loading for button
 * @param {string} agreeText - text for 'Yes' button
 * @param {string} disagreeText - text for 'No' button
 * @param {function} onAgreeAction - action for 'Yes' button
 * @param {function} onDisAgreeAction - action for 'No' button
 * @return {JSX.Element}
 */
function ConfirmDialog({
  open,
  description,
  isSubmitting,
  agreeText,
  disagreeText,
  onAgreeAction,
  onDisAgreeAction,
}) {
  /* Output */
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogContent id="alert-dialog-title">
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="buttonDisagree"
          variant="outlined"
          size="small"
          onClick={onDisAgreeAction}
        >
          {disagreeText}
        </Button>
        <LoadingButton
          data-testid="buttonAgree"
          size="small"
          variant="contained"
          loading={isSubmitting}
          onClick={onAgreeAction}
        >
          {agreeText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = propTypes;
ConfirmDialog.defaultProps = defaultProps;
export default memo(ConfirmDialog);
