import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { removeAccessToken } from "helper/authHelper";
import { auth } from "./auth/authReducer";
import { common } from "./common/commonReducer";
import { signIn } from "./auth/signInReducer";
import { signUp } from "./auth/signUpReducer";
import { forgotPassword } from "./auth/forgotPasswordReducer";
import { resetPassword } from "./auth/resetPasswordReducer";
import { userProfile } from "./auth/userProfileReducer";
import { commonNotification } from "./common/commonNotificationReducer";
import { REMOVE_AUTH_DETAIL } from "../actions";

/**
 * Combines all reducers for store
 */
const allReducers = combineReducers({
  auth,
  common,
  signIn,
  signUp,
  forgotPassword,
  resetPassword,
  userProfile,
  commonNotification,
});

export default (state, action) => {
  if (action.type === REMOVE_AUTH_DETAIL) {
    const initialStore = configureStore({ reducer: allReducers }).getState();
    removeAccessToken();
    return allReducers({ ...initialStore, common: state.common }, action);
  }
  return allReducers(state, action);
};
