export default {
  rootStyles: (theme) => ({
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  }),
  mainTitle: (theme) => ({
    color: theme.palette.warning.light,

    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
    },
  }),
  description: (theme) => ({
    textAlign: "left",
    color: theme.palette.info.darkest,
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      //   paddingLeft: theme.spacing(2),
    },
  }),
  name: (theme) => ({
    color: theme.palette.secondary.darkest,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
    },
  }),
  button: (theme) => ({
    // backgroundColor: theme.palette.background.paper,
    color: theme.palette.grey[900],
    maxWidth: "189px",
    // textTransform:"lowercase"
    borderRadius: 2,
    marginTop: theme.spacing(3.8),
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  }),
  buttonText: (theme) => ({
    color: theme.palette.secondary.darkest,
  }),
  mobileImg: (theme) => ({
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
  aboutUsDescription: (theme) => ({
    width: "70%",
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: theme.spacing(1),
    },
  }),
};
