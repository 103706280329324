/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define tabs for sidebar.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import PublicIcon from "@mui/icons-material/Public";
import HandshakeIcon from "@mui/icons-material/Handshake";
import CategoryIcon from "@mui/icons-material/Category";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AssignmentIcon from "@mui/icons-material/Assignment";

/* Relative Imports */
import { PAGE_ADMIN_DASHBOARD } from "routes/paths";

// ----------------------------------------------------------------------

/* Side bar tabs */
const SidebarConfig = [
  {
    title: "Manage Category",
    href: PAGE_ADMIN_DASHBOARD.categories.absolutePath,
    icon: PeopleIcon,
  },
  {
    title: "Manage Blogs",
    href: PAGE_ADMIN_DASHBOARD.blogs.absolutePath,

    icon: ManageAccountsIcon,
  },
  {
    title: "Manage News",
    href: PAGE_ADMIN_DASHBOARD.news.absolutePath,
    icon: PublicIcon,
  },
  // {
  //   title: 'Manage Clients',
  //   href: PAGE_ADMIN_DASHBOARD.clients.absolutePath,
  //   icon: HandshakeIcon
  // },
  // {
  //   title: 'Manage Categories',
  //   href: PAGE_ADMIN_DASHBOARD.categories.absolutePath,
  //   icon: CategoryIcon
  // },
  // {
  //   title: 'Manage Technologies',
  //   href: PAGE_ADMIN_DASHBOARD.technologies.absolutePath,
  //   icon: ImportantDevicesIcon
  // },
  // {
  //   title: 'Manage Projects',
  //   href: PAGE_ADMIN_DASHBOARD.projects.absolutePath,
  //   icon: FormatListBulletedIcon
  // },
  // {
  //   title: 'Assign Projects',
  //   href: PAGE_ADMIN_DASHBOARD.userProjects.absolutePath,
  //   icon: AssignmentIcon
  // }
];

export default SidebarConfig;
