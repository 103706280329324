/* Imports */
import { useDispatch } from "react-redux";

/* Relative Imports */
import { getAuthAction } from "store/actions/auth/authAction";
// ----------------------------------------------------------------------

/**
 * Handles Jwt token, if present and valid stores in state ,else removes token
 * @return {void}
 */
export default function useInitialize() {
  const dispatch = useDispatch();
  dispatch(getAuthAction());
}
