import Cookies from 'js-cookie';

export const setAccessToken = (isRememberMe, accessToken) => {
  if (isRememberMe) {
    window.localStorage.setItem('accessToken', accessToken);
  } else {
    Cookies.set('accessToken', accessToken);
  }
};

export const removeAccessToken = () => {
  window.localStorage.removeItem('accessToken');
  Cookies.remove('accessToken');
};

export const getAccessToken = () => {
  return (
    window.localStorage.getItem('accessToken') || Cookies.get('accessToken')
  );
};

export const getAccessTokenStorage = () => {
  return !!window.localStorage.getItem('accessToken');
};
