export default {
  footerRootstyles: (theme) => ({
    backgroundColor: theme.palette.background.neutral,
    padding: theme.spacing(4, 16),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5),
    },
  }),
  mainTitle: (theme) => ({
    color: theme.palette.background.default,
  }),
  footerSubtitle: (theme) => ({
    color: theme.palette.background.default,

    maxWidth: "473px",
  }),
  mainMenuTitle: (theme) => ({
    color: theme.palette.background.default,
  }),
  nonProfitText: (theme) => ({
    color: theme.palette.background.default,
  }),
  getintouchText: (theme) => ({
    color: theme.palette.background.default,
  }),

  navLinks: (theme) => ({
    color: theme.palette.background.default,
    "&:hover": {
      color: theme.palette.error.dark,
    },
  }),

  supportText: (theme) => ({
    color: theme.palette.grey[200],
    maxWidth: "350px",
    paddingTop: theme.spacing(2.5),
  }),

  mainMenuStack: (theme) => ({
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 5, 5, 0),
    },
  }),
  nonProfitStack: (theme) => ({
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 5, 5, 0),
    },
  }),
  iconBox: (theme) => ({
    color: theme.palette.grey[200],
    backgroundColor: theme.palette.grey[900],
    padding: theme.spacing(1.5, 2, 0.75, 2),
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  }),
  iconStack: (theme) => ({
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(5),
    },
  }),
  copyrightBox: (theme) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.neutral,
    color: theme.palette.background.default,
    padding: theme.spacing(2, 0, 2, 0),
  }),
  dividerBox: (theme) => ({
    margin: theme.spacing(0, 4, 0, 4),
    backgroundColor: theme.palette.background.neutral,
  }),
  termsStyles: (theme) => ({
    color: theme.palette.grey[200],
    paddingTop: theme.spacing(5),
    "&:hover": {
      color: theme.palette.error.dark,
    },
  }),
  privacyStyles: (theme) => ({
    color: theme.palette.grey[200],
    paddingTop: theme.spacing(3),
    "&:hover": {
      color: theme.palette.error.dark,
    },
  }),
};
