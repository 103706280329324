/**
 * Imports
 */
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

/**
 * Relative Imports
 */

import { PAGE_MAIN, ROOT_PATH } from "routes/paths";

// ----------------------------------------------------------------------

const propTypes = {
  children: PropTypes.element.isRequired,
};

// ----------------------------------------------------------------------

/**
 * @return {JSX.Element}
 */
function UserGuard({ children }) {
  /* Hooks */
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();
  const returnUrl = new URLSearchParams(location.search).get("returnurl");

  /* Output */
  if (isAuthenticated) {
    return <Navigate to={returnUrl || ROOT_PATH} />;
  }

  return children;
}

UserGuard.propTypes = propTypes;
export default UserGuard;
