/**
 * @copyright @2022 Jiyana Foundations. All rights reserved.
 * @description Header Page
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 2/Jan/2023
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React, { memo, useState } from "react";
import { globalHistory } from "@reach/router";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  Hidden,
  IconButton,
  Link,
  Menu,
  Stack,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  Button,
  ListItemButton,
  ListItemText,
  List,
  Avatar,
  Divider,
  MenuItem,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

/* Relative Imports */
import Hamburger from "../Hamburger";
import SearchInput from "../InputField/SearchInput";

/* Local Imports */
import styles from "./index.style";
import { useDispatch, useSelector } from "react-redux";
import MenuPopover from "components/MenuPopover";
import { ConfirmDialog } from "components/Dialog";
import { setLogoutAction } from "store/actions/auth/authAction";
import AccountPopover from "components/AccountPopover";
import { PAGE_AUTH, PAGE_ROOT } from "routes/paths";

function Header() {
  /* Constants */
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const theme = useTheme();
  const pathName = globalHistory.location.pathname;
  const navItems = [
    {
      name: "About Us",
      link: "aboutUs",
    },
    {
      name: "Our goal",
      link: "ourGoal",
    },
    {
      name: "Our work",
      link: "ourWork",
    },
  ];
  console.log(pathName);
  /* States */
  const tabletDownMatches = useMediaQuery(theme.breakpoints.down("md"));

  const handleNavLinkClick = (event, anchorId) => {
    event.preventDefault();
    const targetElement = document.querySelector(anchorId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  /* Output */
  return (
    <AppBar position="static" sx={styles.navbarContainer}>
      {tabletDownMatches && <Hamburger />}
      <Box>
        {!tabletDownMatches && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="space-between"
            sx={styles.appBarContainer}
          >
            <Box sx={styles.logoBox}>
              <a href="/">
                <img
                  src={"/assets/images/logo_jiyana.png"}
                  alt="logo"
                  href="/"
                  style={{ height: "100px", width: "150px", width: "100%" }}
                />
              </a>
            </Box>
            <Stack direction="row">
              <List sx={styles.menuStyle}>
                {pathName == "/" &&
                  navItems.map((item, index) => (
                    <ListItemButton
                      key={index}
                      // href={`/#${item.link}`}
                      onClick={(event) =>
                        handleNavLinkClick(event, `#${item.link}`)
                      }
                      // selected={pathName.includes(item.link)}
                      sx={styles.listItemButton}
                    >
                      <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{ variant: "subtitle1" }}
                        sx={{ textAlign: "center" }}
                      />
                    </ListItemButton>
                  ))}

                {/* {isAuthenticated ? (
                  <Box sx={{ mr: 10 }}>
                    <AccountPopover userData={user} />
                  </Box>
                ) : ( */}
                <Button sx={styles.donateButton} href="/become-a-volunteer">
                  <Typography variant="button" sx={{ width: "100%" }}>
                    Become a Volunteer
                  </Typography>
                </Button>
                {/* )} */}
              </List>
            </Stack>
          </Stack>
        )}
      </Box>
    </AppBar>
  );
}
export default memo(Header);
