/* Imports */
import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_SUCCESS,
} from "../../actions";

// ----------------------------------------------------------------------

/** Initial State */
const forgotPasswordInitialState = {
  loading: false,
  success: null,
  failure: null,
};

/**
 * Reducer method for particular action
 * @param {*} state initial state
 * @param {*} action particular action
 * @returns updated state
 */
export const forgotPassword = (
  state = forgotPasswordInitialState,
  action = {}
) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        failure: null,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        success: null,
        failure: action.payload,
      };
    case FORGOT_PASSWORD_RESET:
      return {
        ...state,
        loading: false,
        success: null,
        failure: null,
      };
    default:
      return state;
  }
};
