/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create my avatar component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 18/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */

import { memo } from "react";
import { Avatar } from "@mui/material";

/* Relative Imports */
// import SessionContext from 'context/SessionContext';

// ----------------------------------------------------------------------

/**
 * Component to show logged in user's profile picture.
 *
 * @component
 * @return {JSX.Element}
 */

function MyAvatar({ ...other }) {
  /* Hooks */
  // const { user } = useContext(SessionContext);

  /* Output */
  return <Avatar src="" alt="" {...other} />;
}

export default memo(MyAvatar);
