/**
 * @copyright @ 2021 Cognition Solutions. All rights reserved.
 * @description Reducer for common use.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created  : 18/Aug/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import {
  COMMON_FAILURE,
  SET_COUNTRY_LIST,
  SET_TAXONOMIES,
  GET_TAXONOMIES,
  SET_USERS_LIST,
  SET_LOCATION_COUNTRY_CODE,
} from "../../actions";

// ----------------------------------------------------------------------

/** Initial State */
const commonInitialState = {
  loading: false,
  countries: [],
  countryCode: 91,
  users: [],
  taxonomies: [],
  failure: null,
};

/**
 * Reducer method for particular action
 * @param {*} state initial state
 * @param {*} action particular action
 * @returns updated state
 */
export const common = (state = commonInitialState, action = {}) => {
  switch (action.type) {
    case SET_COUNTRY_LIST:
      return {
        ...state,
        countries: action.payload,
      };
    case COMMON_FAILURE:
      return {
        ...state,
        failure: action.payload,
      };
    default:
      return state;
  }
};
