/* Mapping of all action types for Reducer and Saga operations */
/* Common */
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const SET_USERS_LIST = "SET_USERS_LIST";
export const GET_LOCATION_COUNTRY_CODE = "GET_LOCATION_COUNTRY_CODE";
export const SET_LOCATION_COUNTRY_CODE = "SET_LOCATION_COUNTRY_CODE";
export const COMMON_FAILURE = "COMMON_FAILURE";

/* Common Notification */
export const SEND_EMAIL_NOTIFICATION = "SEND_EMAIL_NOTIFICATION";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAILURE = "NOTIFICATION_FAILURE";
export const NOTIFICATION_RESET = "NOTIFICATION_RESET";

/* User */
export const USER_FAILURE = "USER_FAILURE";

/* Auth */
export const GET_AUTH_DETAIL = "GET_AUTH_DETAIL";
export const SET_AUTH_DETAIL = "SET_AUTH_DETAIL";
export const REMOVE_AUTH_DETAIL = "REMOVE_AUTH_DETAIL";

/* Sign In */
export const SIGNIN_PASSWORD_REQUEST = "SIGNIN_PASSWORD_REQUEST";
export const SIGNIN_EMAIL_OTP_REQUEST = "SIGNIN_EMAIL_OTP_REQUEST";
export const SIGNIN_EMAIL_OTP_VERIFY = "SIGNIN_EMAIL_OTP_VERIFY";
export const SIGNIN_SMS_OTP_REQUEST = "SIGNIN_SMS_OTP_REQUEST";
export const SIGNIN_SMS_OTP_VERIFY = "SIGNIN_SMS_OTP_VERIFY";
export const SIGNIN_RESEND_EMAIL_OTP_REQUEST =
  "SIGNIN_RESEND_EMAIL_OTP_REQUEST";
export const SIGNIN_RESEND_SMS_OTP_REQUEST = "SIGNIN_RESEND_SMS_OTP_REQUEST";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
export const SIGNIN_RESET = "SIGNIN_RESET";

/* Sign Up */
export const SIGNUP_EMAIL_OTP_REQUEST = "SIGNUP_EMAIL_OTP_REQUEST";
export const SIGNUP_EMAIL_OTP_VERIFY = "SIGNUP_EMAIL_OTP_VERIFY";
export const SIGNUP_UPDATE_MOBILE_REQUEST = "SIGNUP_UPDATE_MOBILE_REQUEST";
export const SIGNUP_RESEND_EMAIL_OTP_REQUEST =
  "SIGNUP_RESEND_EMAIL_OTP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_RESET = "SIGNUP_RESET";

/* Forgot Password */
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";

/* Reset Password */
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";

/* User Profile */
export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const USER_PROFILE_FAILURE = "USER_PROFILE_FAILURE";

/* Other Filter */
export const SET_OTHER_FILTER = "SET_OTHER_FILTER";

/* Sorting */
export const SET_SORT_BY = "SET_SORT_BY";

/* Paging */
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_PAGE_SIZE = "SET_PAGE_SiZE";
