export default {
  hamburgerStack: (theme) => ({
    padding: theme.spacing(5, 10, 5, 10),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 3, 2, 3),
    },
    backgroundColor: theme.palette.background.default,
  }),
  Drawer: (theme) => ({
    "& .MuiPaper-root": {
      width: "30%",
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        width: "70%",
      },
    },
  }),
  crossButtonBox: (theme) => ({
    display: "flex",
    justifyContent: "flex-end",
  }),
  navStack: (theme) => ({
    padding: theme.spacing(5, 0, 0, 8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 0, 0, 3),
    },
  }),
  navItems: (theme) => ({
    color: theme.palette.primary.main,
  }),
  crossButton: (theme) => ({
    color: theme.palette.primary.main,
  }),
  donateButton: (theme) => ({
    color: theme.palette.error.main,
    borderRadius: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  }),
  menuStyle: (theme) => ({
    gap: "15px", // flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.secondary.lighter,
    "& .MuiListItemButton-root": {
      "&:hover": {
        color: theme.palette.primary.main,
        "& .MuiListItemText-root": {
          "&:hover": {
            color: theme.palette.warning.lighter,
          },
        },
      },
      "&.Mui-selected": {
        color: theme.palette.primary.main,

        "& .MuiListItemText-root": {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.background.default,

          "& .MuiTypography-root": {
            fontWeight: theme.typography.fontWeightSemiBold,
          },
        },
      },
    },
  }),
};
