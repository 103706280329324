/* Imports */
import {
  GET_USER_PROFILE_REQUEST,
  SET_USER_PROFILE,
  USER_PROFILE_FAILURE,
} from "..";

// ----------------------------------------------------------------------

/**
 * Returns the action to get user profile
 * @returns Type
 */
export const getUserProfileAction = (data) => {
  return {
    type: GET_USER_PROFILE_REQUEST,
    payload: data,
  };
};

/**
 * Returns the action to set user profile
 * @param {*} data user profile data
 * @returns Type and Payload
 */
export const setUserProfileAction = (data) => {
  return {
    type: SET_USER_PROFILE,
    payload: data,
  };
};

/**
 * Returns the action to set user profile failure
 * @param {*} data status code and message
 * @returns Type and Payload
 */
export const userProfileFailureAction = (data) => {
  return {
    type: USER_PROFILE_FAILURE,
    payload: data,
  };
};
