// ----------------------------------------------------------------------

/* Imports */
import {
  SIGNIN_EMAIL_OTP_REQUEST,
  SIGNIN_EMAIL_OTP_VERIFY,
  SIGNIN_FAILURE,
  SIGNIN_PASSWORD_REQUEST,
  SIGNIN_RESEND_EMAIL_OTP_REQUEST,
  SIGNIN_RESEND_SMS_OTP_REQUEST,
  SIGNIN_RESET,
  SIGNIN_SMS_OTP_REQUEST,
  SIGNIN_SMS_OTP_VERIFY,
  SIGNIN_SUCCESS,
} from "..";

// ----------------------------------------------------------------------

/**
 * Returns the action for Sign In with Password
 * @param username - users username
 * @param password - users password
 * @returns Type and payload
 */
export const signInWithPasswordAction = (data) => {
  return {
    type: SIGNIN_PASSWORD_REQUEST,
    payload: data,
  };
};

/**
 * Returns the action for Send Email OTPs
 * @param email - user email address
 * @returns Type and payload
 */
export const sendEmailOtpAction = (data) => {
  return {
    type: SIGNIN_EMAIL_OTP_REQUEST,
    payload: data,
  };
};

/**
 * Returns the action for Verify Email
 * @param email - users email address
 * @param otp - otp recieved on users email
 * @returns Type and payload
 */
export const verifyEmailOtpAction = (data) => {
  return {
    type: SIGNIN_EMAIL_OTP_VERIFY,
    payload: data,
  };
};

/**
 * Returns the action for Send SMS OTP
 * @param mobile number - users mobile number
 * @param countryCode - users country code
 * @returns Type and payload
 */
export const sendSmsOtpAction = (data) => {
  return {
    type: SIGNIN_SMS_OTP_REQUEST,
    payload: data,
  };
};

/**
 * Returns the action for Verify SMS OTP
 * @param mobileNumber - users mobile number
 * @param countryCode - users country code
 * @param otp - otp recieved on users email
 * @returns Type and payload
 */
export const verifySmsOtpAction = (data) => {
  return {
    type: SIGNIN_SMS_OTP_VERIFY,
    payload: data,
  };
};

/**
 * Returns the action for resend email otp action
 * @param email - user email address
 * @returns Type and Payload
 */
export const resendEmailOtpAction = (data) => {
  return {
    type: SIGNIN_RESEND_EMAIL_OTP_REQUEST,
    payload: data,
  };
};

/**
 * Returns the action for resend sms otp action
 * @param mobile number - users mobile number
 * @param countryCode - users country code
 * @returns Type and Payload
 */
export const resendSmsOtpAction = (data) => {
  return {
    type: SIGNIN_RESEND_SMS_OTP_REQUEST,
    payload: data,
  };
};

/**
 * Returns the action for Sign In Success
 * @param email - users email address
 * @param password - users
 * @returns Type and payload
 */
export const signInSuccessAction = (data) => {
  return {
    type: SIGNIN_SUCCESS,
    payload: data,
  };
};

/**
 * Returns the action for Sign In Failure
 * @param email - users email address
 * @param password - users password
 * @returns Type and payload
 */
export const signInFailureAction = (data) => {
  return {
    type: SIGNIN_FAILURE,
    payload: data,
  };
};

/**
 * Returns the action for Sign In Reset
 * @returns Type
 */
export const signInResetAction = () => {
  return {
    type: SIGNIN_RESET,
  };
};
