/**
 * @copyright @2022 Jiyana Foundation. All rights reserved.
 * @description Page to create admin dashboard page component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 02/Jan/2023
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "../TextInput";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

/* Relative Imports */
import { registerUserRequest } from "../../services/auth";
import DeleteIcon from '@mui/icons-material/Delete';
/* Local Imports */
import styles from "./index.style";
import { PAGE_AUTH, PAGE_ROOT } from "../../routes/paths";
import useSnackbarClose from "hooks/useSnackbarClose";
import { experience } from "constants/appConstant";

// ----------------------------------------------------------------------

function VolunteerForm({ email, password }) {
  /* States */
  const [volunteerDetails, setVolunteerDetails] = useState(false);
  const [date, setDate] = useState(dayjs(""));
  const [gender, setGender] = useState("female");

  /* Constants */
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState();

  const initialValues = {
    fullName: "",
    phoneNumber: "",
    emailAddress: "",
    edQualification: "",
    gender: "",
    datesAvailable: "",
    hoursAvailable: "",
    whyInterested: "",
    areasOfInterest: "",
    previousVolunteering: "",
    name_email_references: "",
    emergency_contact: "",
  };
  const { showSnackbar } = useSnackbarClose();

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const numbersRegExp = /^\d{10}$/;

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setProfileImage(e.target.files[0]);
    }
  }

  function handleDeleteImage() {
  // Clear both the profileImage state and the file input value
  setProfileImage(null); // Assuming setProfileImage(null) clears the image state
  document.getElementById("profileImage").value = ""; // Clear the file input value
}

  const areasOfInterestData = [
    "Fundraising",
    "Communications",
    "Health",
    "Ares of Marketing",
    "Other",
  ];

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .max(40)
      .required("Please enter your name"),
    emailAddress: Yup.string()
      .email("Please enter valid email address.")
      .required("Please enter your email address."),
    phoneNumber: Yup.string()
      .matches(numbersRegExp, "Please enter valid phone number.")
      .required("Please enter your phone number."),
    hoursAvailable: Yup.number()
      .required("Enter the hours available")
      .max(72, "Enter hours less than 72")
      .positive("Enter valid hours")
      .typeError("Enter valid hours"),
    whyInterested: Yup.string()
      .required("Please tell us why you are interested")
      .max(500, "Maximum limit exceeded"),
  });

  /* Functions */

  /**
   *
   *
   *
   */
  const handleCheckbox = () => {
    setVolunteerDetails(!volunteerDetails);
  };

  /**
   *
   *
   *
   */
  const handleFormSubmit = async (values, { resetForm }) => {
    console.log(values);
    try {
      const formData = new FormData();
      formData.append("FullName", values.fullName);
      formData.append("Email", values.emailAddress);
      formData.append("Password", password);
      formData.append("ProfileImage", profileImage ? profileImage.name : "");
      formData.append("Phone", values.phoneNumber);
      formData.append("DOB", date);
      formData.append("Gender", gender);
      formData.append("EducationQualification", values.edQualification);
      formData.append("AvilableHoursPerWeek", values.hoursAvailable);
      formData.append("ReasonOfInterest", values.whyInterested);
      formData.append("AreaOfInterest", values.areasOfInterest);

      formData.append("PreviousExperience", values.previousVolunteering);
      formData.append("Isvoluteer", 1);
      const response = await registerUserRequest(formData);

      if (response?.Status.ResponseCode === 200) {
        // navigate(PAGE_ROOT.home.absolutePath);
        showSnackbar(
          "Form Submitted Successfully. We will get back to you soon.",
          "success"
        );
        resetForm();
        setDate(dayjs(""));
      } else if (response?.Status.ResponseCode === 205) {
        showSnackbar("Email or phone number already exists", "error");
      }
    } catch (error) {
      console.log(error);
      showSnackbar(
        "Unable to process your request. Please try again.",
        "error"
      );
    }
  };

  /* Output */
  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          values,
          handleBlur,
          handleSubmit,
          handleChange,
          isValid,
          touched,
        }) => (
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={styles.formContainer}>
              <Box sx={styles.volunteerForm}>
                <Typography variant="h2">Enter your Details</Typography>
              </Box>
              <Grid container spacing={5} mb={2}>
                <Grid item md={6} sm={6} xs={12} sx={styles.grid}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={styles.formStack}
                  >
                    <Typography variant="h9" sx={styles.fullNameText}>
                      Full Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextInput
                      name="fullName"
                      placeholder="Enter you name"
                      color="warning"
                      value={values.fullName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 40 }}
                      error={Boolean(touched.fullName && errors.fullName)}
                      helperText={String(touched.fullName && errors.fullName)}
                      sx={styles.fullName}
                    />
                  </Stack>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={styles.grid}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={styles.formStack}
                  >
                    <Typography variant="h9" sx={styles.fullNameText}>
                      Email Address <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextInput
                      name="emailAddress"
                      color="warning"
                      placeholder="Enter your email address"
                      value={values.emailAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.emailAddress && errors.emailAddress
                      )}
                      helperText={String(
                        touched.emailAddress && errors.emailAddress
                      )}
                      sx={styles.fullName}
                    />
                  </Stack>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={styles.grid}>
                  <Stack
                    direction="column"
                    sx={styles.formStack}
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Typography variant="h9" sx={styles.fullNameText}>
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextInput
                      fullWidth
                      name="phoneNumber"
                      placeholder="Enter your phone Number"
                      // width={{ md: "60%", sm: "100%" }}
                      color="warning"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={String(
                        touched.phoneNumber && errors.phoneNumber
                      )}
                      sx={styles.fullName}
                    />
                  </Stack>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={styles.grid}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={styles.formStack}
                  >
                    <Typography variant="h9" sx={styles.fullNameText}>
                      Date of Birth
                    </Typography>

                    <Box sx={styles.formBox}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          inputFormat="DD/MM/YYYY"
                          disableFuture
                          openTo="year"
                          views={["year", "month", "day"]}
                          value={date}
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={false}
                              sx={{ width: "100%" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={styles.grid}>
                  <Stack
                    direction="column"
                    sx={styles.formStack}
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Typography variant="h9" sx={styles.fullNameText}>
                      Gender
                    </Typography>
                    <Box sx={[styles.formBox, { my: 1 }]}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={gender}
                        onChange={(event) => {
                          setGender(event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="others"
                          control={<Radio />}
                          label="Others"
                        />
                      </RadioGroup>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={styles.grid}>
                  <Stack
                    direction="column"
                    sx={styles.formStack}
                    justifyContent="center"
                    alignItems="flex-start"
                    mt={2}
                  >
                    <Typography variant="h9" sx={styles.fullNameText}>
                      Education Qualification
                    </Typography>
                    <Box sx={styles.formBox}>
                      <Select
                        fullWidth
                        name="edQualification"
                        sx={styles.selectBox}
                        value={values.edQualification}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem key="-1" value="" sx={styles.menuItemStyle}>
                          - None -
                        </MenuItem>
                        {experience.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item}
                            sx={styles.menuItemStyle}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={styles.grid}>
                  <Stack
                    direction="column"
                    sx={styles.formStack}
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Stack direction='row' alignItems='center' justifyContent='center' mb={1}>
                    <Typography variant="h9" sx={styles.fullNameText} >
                      Upload profile photo
                      </Typography>
                      {profileImage &&
                        <Button onClick={() => handleDeleteImage()}>
                          <DeleteIcon />
                        </Button>
                      }
                      </Stack>
                    <Stack sx={styles.uploadFilesButtonBox}>
                      <input
                        id="profileImage"
                        type="file"
                        onChange={handleImageChange}
                      />
                     
                      <Box
                        sx={{
                          mt: 1.5,
                        }}
                      >
                        <img
                          src={
                            profileImage
                              ? URL.createObjectURL(profileImage)
                              : ""
                          }
                          style={{
                            maxHeight: "100px",
                            maxWidth: "200px",
                            width: "100%",
                          }}
                        />
                      </Box>
                    </Stack>
                    
                  </Stack>
                </Grid>
              </Grid>

              <>
                <Typography variant="h6" sx={styles.subTitle}>
                  Availability
                </Typography>

                <Stack
                  direction="column"
                  sx={styles.formStack}
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Typography variant="h9" sx={styles.fullNameText}>
                    How many hours per week are you available?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextInput
                    name="hoursAvailable"
                    color="warning"
                    placeholder="Ex: 20"
                    value={values.hoursAvailable}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.hoursAvailable && errors.hoursAvailable
                    )}
                    helperText={String(
                      touched.hoursAvailable && errors.hoursAvailable
                    )}
                    sx={styles.fullName}
                  />
                </Stack>

                <Typography variant="h6" mt={3} sx={styles.subTitle}>
                  Interests and experience
                </Typography>

                <Stack
                  direction="column"
                  sx={styles.formStack}
                  justifyContent="center"
                  alignItems="flex-start"
                  mt={2}
                >
                  <Typography variant="h9" sx={styles.fullNameText}>
                    Why are you interested in volunteering with us?
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextInput
                    fullWidth
                    multiline
                    name="whyInterested"
                    rows={2}
                    color="warning"
                    value={values.whyInterested}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.whyInterested && errors.whyInterested
                    )}
                    helperText={String(
                      touched.whyInterested && errors.whyInterested
                    )}
                    sx={styles.fullName}
                  />
                </Stack>

                <Stack
                  direction="column"
                  sx={styles.formStack}
                  justifyContent="center"
                  alignItems="flex-start"
                  mt={2}
                >
                  <Typography variant="h9" sx={styles.fullNameText}>
                    Tell us about your previous volunteering experience
                  </Typography>
                  <TextInput
                    multiline
                    rows={2}
                    name="previousVolunteering"
                    color="warning"
                    value={values.previousVolunteering}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.previousVolunteering &&
                        errors.previousVolunteering
                    )}
                    helperText={String(
                      touched.previousVolunteering &&
                        errors.previousVolunteering
                    )}
                    sx={styles.fullName}
                  />
                </Stack>
                <Stack
                  direction="column"
                  sx={styles.formStack}
                  justifyContent="center"
                  alignItems="flex-start"
                  mt={2}
                >
                  <Typography variant="h9" sx={styles.fullNameText}>
                    Areas of Interest
                  </Typography>
                  <Box sx={styles.formBox}>
                    <Select
                      fullWidth
                      name="areasOfInterest"
                      sx={styles.selectBox}
                      value={values.areasOfInterest}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem key="-1" value="" sx={styles.menuItemStyle}>
                        - None -
                      </MenuItem>
                      {areasOfInterestData.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item}
                          sx={styles.menuItemStyle}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Stack>

                <Box sx={styles.confirmText}>
                  <Typography variant="body2">
                    By submitting this application, I affirm that the facts set
                    forth in it are true and complete. I understand that if I am
                    accepted as a volunteer, any false statements, omissions, or
                    other misrepresentations made by me on this application may
                    result in my immediate dismissal.
                  </Typography>
                </Box>
              </>

              <Box sx={styles.submitButtonBox}>
                <Button sx={styles.submitButton} type="submit">
                  <Typography variant="button">Submit</Typography>
                </Button>
              </Box>
            </Box>

            {/* </Grid> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default VolunteerForm;
