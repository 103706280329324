/* Imports */
import { USER_PROFILE_FAILURE } from "../../actions";

// ----------------------------------------------------------------------

/** Initial State */
const userProfileInitialState = {
  failure: null,
};

/**
 * Reducer method for particular action
 * @param {*} state initial state
 * @param {*} action particular action
 * @returns updated state
 */
export const userProfile = (state = userProfileInitialState, action = {}) => {
  switch (action.type) {
    case USER_PROFILE_FAILURE:
      return {
        ...state,

        failure: action.payload,
      };

    default:
      return state;
  }
};
