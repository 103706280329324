/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to create Header for admin dashboard pages.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

/* Relative Imports */

/* Local Imports */
import styles from "../index.style";
import AccountPopover from "components/AccountPopover";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

/**
 * Header for the admin dashboard pages
 *
 * @component
 * @param {function} onMobileNavOpen - callback function to handle open/close on click of side bar icon.
 * @return {JSX.Element}
 */
function Header({ onMobileNavOpen }) {
  /* Hooks */

  const theme = useTheme();
  const laptopDownMatches = useMediaQuery(theme.breakpoints.down("laptop"));
  const user = useSelector((state) => state.auth.user);

  /* Output */
  return (
    <Container maxWidth={false} sx={styles.header}>
      {laptopDownMatches && (
        <IconButton
          color="inherit"
          onClick={onMobileNavOpen}
          sx={styles.menuIcon}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Box sx={styles.rightOptions}>
        <AccountPopover userData={user} />
      </Box>
    </Container>
  );
}

export default memo(Header);
