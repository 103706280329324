/**
 * @copyright @2022 Techechelons Infosolution Pvt. Ltd. All rights reserved.
 * @description Page to define the constants.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 16/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

export const numbersRegExp = /^\d{10}$/;

export const platformItems = ["Android", "Ios", "Web"];

export const toastMessages = {
  success: {
    auth: {
      forgotPassword: "Reset password link sent successfully.",
      resetPassword:
        "Password changed successfully. Please sign in with new password.",
    },
    adminDashboard: {
      roleSaved: "Role saved successfully.",
      roleUpdated: "Role updated successfully.",
      roleDeleted: "Role deleted successfully.",
      userProjectsSaved: "Projects are assigned successfully.",
      userProjectsUpdated: "User's Projects are updated successfully.",
      userProjectsDeleted: "Projects are removed successfully.",
    },
  },
  error: {
    common: "Unable to process your request. Please try again.",
    auth: {
      notRegistered: "Email is not registered yet.",
      invalidCredentials: "Invalid Credentials.",
      invalidResetToken:
        "Link is either expired or invalid. Please try again with new link.",
    },
    adminDashboard: {
      roleDuplicate: "Role with the same name, already exist.",
    },
  },
};

export const aboutData = [
  {
    image: "/assets/images/voluteerImg.png",
    title: "Our Vision",
    description:
      "Our vision is to empower individuals and communities with essential digital skills and resources.",
  },
  {
    image: "/assets/images/fundraiseImg.png",
    title: "Our Mission",
    description:
      "Our mission is to bridge the digital divide and enable a more inclusive society.",
  },
  {
    image: "/assets/images/donatingImg.png",
    title: "Become Volunteer",
    description: "Be a Volunteer with Jiyana Foundation and Transform Lives.",
  },
];

export const aboutUsData = {
  image: "/assets/images/aboutus.jpg",
  title: "About Us",
  description: [
    "There are still some parts of India where  technology is still inaccessible. At Jiyana, we believe in harnessing the power of technology to make a positive impact in the lives of underprivileged communities.",
    "As a non-governmental organization (NGO) registered under Section 8 of the Companies Act, we are dedicated to promoting computer literacy, bridging the digital divide, and supporting other NGOs in their endeavour.",
    "We understand the transformative potential of technology in today's world. Through our various initiatives, we strive to provide access to digital resources, education, and opportunities to individuals who lack the means to fully participate in the digital age.",
  ],
};

export const joinCampaignData = {
  title: "Empower Our Mission!",
  description:
    "Ready to make a meaningful impact? Become a vital part of our NGO's dedicated team by clicking 'Become a Volunteer.' Join us in our mission to create positive change in our community and beyond. Your time and passion can help us drive progress and make a lasting difference in the lives of those we serve. Together, we can create a brighter future!",
  buttonText: "Become a Volunteer",
};

export const experience = [
  "10th Pass",
  "12th Pass",
  "Under Graduate",
  "Post Graduate",
  "PH.D",
  "Others",
];

export const sliderData = [
  {
    image: "/assets/images/gallery/1.jpeg",
  },
  {
    image: "/assets/images/gallery/2.jpeg",
  },
  {
    image: "/assets/images/gallery/3.jpeg",
  },
  {
    image: "/assets/images/gallery/4.jpeg",
  },
  {
    image: "/assets/images/gallery/5.jpeg",
  },
  {
    image: "/assets/images/gallery/6.jpeg",
  },
];
