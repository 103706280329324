/**
 * @copyright @2022 Jiyana Foundations. All rights reserved.
 * @description Sidebar Page for Admin
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created: 2/Jan/2023
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { Box, List, Stack } from "@mui/material";
import React from "react";

/* Relative Imports */
import SidebarItem from "./SidebarItem";
import sidebarConfig from "../helper/sidebarConfig";
import WebsiteLogo from "components/WebsiteLogo";

/* Local Imports */
import styles from "../index.style";

function Sidebar() {
  /* Output */
  return (
    <Stack height="100%">
      <Box sx={styles.logoContainer}>
        <WebsiteLogo />
      </Box>
      <List>
        {sidebarConfig.map((item, index) => (
          <SidebarItem
            href={item.href}
            key={index}
            title={item.title}
            icon={item.icon}
          />
        ))}
      </List>
    </Stack>
  );
}

export default Sidebar;
