export default {
  searchField: (theme) => ({
    width: 150,
    "& .MuiInput-input": {
      ...theme.typography.subtitle1,
      paddingLeft: 0,
      color: theme.palette.secondary.light1,
    },
  }),
  inputStyles: (theme) => ({}),
};
