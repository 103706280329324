/**
 * @copyright @ 2021 Cognition Solutions. All rights reserved.
 * @description Theme Config is used to set themes to its children components.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Nithin Kumar
 * Date Created  : 01/Aug/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
// import "@mui/lab/themeAugmentation";
// ----------------------------------------------------------------------

/* Local Imports */
import breakpoints from "./breakpoints";
// import componentsOverride from "./overrides";
import palette from "./palette";
import shape from "./shape";
import typography from "./typography";

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.node,
};

/**
 * Theme Config is used to set themes to its children components
 *
 * @param children - nested components to set the theme
 * @returns provides theme to its children
 */
function ThemeConfig({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette: { ...palette.light, mode: "light" },
      shape,
      typography,
      breakpoints,
    }),
    []
  );

  const theme = createTheme(themeOptions);
  // theme.components = componentsOverride(theme);

  /* Output */
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeConfig.propTypes = propTypes;
export default ThemeConfig;
